import React from 'react'
import { Button, Grid, Typography, Link, Box } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { useForm } from 'react-hook-form'
import { IForgotPassword } from './IForgotPassword'
import { useLoading } from '../../../../contexts/LoadingContext'
import { useAlert } from '../../../../contexts/AlertContext'
import Onboarding from '../../../../layouts/Onboarding/Onboarding'

import api from '../../../../Utils/APIService'
import { useNavigate } from 'react-router-dom'

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const methods = useForm<IForgotPassword>({ defaultValues: { email: '' } })
    const theme = createTheme()
    const { handleSubmit, control, setValue } = methods
    const [alertSeverity, setAlertSeverity] = React.useState({ status: '', msg: '' })
    const { setAlert } = useAlert()
    const { setLoading, isLoading } = useLoading()

    const handleForgotPassword = async (data: IForgotPassword) => {
        setAlertSeverity({ status: '', msg: '' })
        setLoading(true)

        try {
            const prmItem = {
                Email: data.email,
            }

            const response = await api.post('users/forgotpassword', JSON.stringify(prmItem))

            console.log(response)
            if (!response.data) {
                const data = response.data
                setLoading(false)
                setAlertSeverity({ status: 'error', msg: t('Error occurred. Please try again later.') })
                setAlert({
                    message: data.msg,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            } else {
                setLoading(false)
                //setAlertSeverity({ status: 'OK', msg: t('Forgot Password sent to your email!') })
                setAlert({
                    message: t('Forgot Password sent to your email!'),
                    severity: 'success',
                    datetime: Date.now().toString(),
                })
                navigate('/login')
            }
        } catch (err: any) {
            setLoading(false)
            setAlert({
                message: err.response.data.msg,
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const handleError = (error: any) => {
        setAlert({
            message: t('Error'),
            severity: 'error',
            datetime: Date.now().toString(),
        })
    }
    return (
        <Onboarding>
            <Typography component="h1" variant="h5" align="center">
                {t('Reset Password')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(handleForgotPassword, handleError)} sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="email"
                            label={t('Email')}
                            required={t('Empty field!')}
                            autoComplete={'email'}
                            autoFocus={true}
                            inputType={EInputType.Email}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" disabled={isLoading} variant="contained" color="primary" fullWidth>
                            {isLoading ? t('Sending password recovery email') : t('Reset Password')}
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Typography variant="body2" align="center">
                            {t('Do you have already an account?')}{' '}
                            <Link underline="none" href="/login">
                                {t('Home')}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Onboarding>
    )
}

export default ForgotPassword
