import React, { createContext, useContext, useState, ReactNode, useEffect, lazy } from 'react'
import { IAction } from '../components/Common/MenuActions/IAction'
import { useAlert } from './AlertContext'
import { Methods } from '../Utils/Utils'
import { useTranslation } from 'react-i18next'
import api from '../Utils/APIService'

import { Operation } from '../components/Common/Types/CommonType'
import i18n from '../i18n'

// Define the type for the context value
type Mode = 'form' | 'list'

interface screenMode {
    mode: Mode
    item: any
    url: string
}

interface IAfterActionCallback {
    save: boolean
    item: any
    error: string
    operation: Operation
}

interface CurrentScreenContextType {
    hasActionsRequireBulk: boolean
    setHasActionsRequireBulk: (value: boolean) => void
    currentPageName: string
    setCurrentPageName: (value: string) => void
    currentPageIcon: string
    setCurrentPageIcon: (value: string) => void
    selectedRecordIDs: number[]
    setSelectedRecordIDs: (value: number[]) => void
    currentRoute: string
    setCurrentRoute: (value: string) => void
    currentMenuID: number
    setCurrentMenuID: (value: number) => void
    actions: IAction[]
    setActions: (value: IAction[]) => void
    selectedAction: IAction | null
    setSelectedAction: (value: IAction | null) => void
    componentMap: any
    setComponentMap: (value: any) => void
    DynamicComponent: React.ComponentType<any> | null
    handleSelectedAction: (shortCode: string) => void
    handleProceed: (prm: any, userName: string, OpUserID: number, OrganizationID: number) => void
    openQuickActionDialog: boolean
    setOpenQuickActionDialog: (value: boolean) => void
    openFullActionDialog: boolean
    setOpenFullActionDialog: (value: boolean) => void
    openSwipeScreenDialog: boolean
    setOpenSwipeScreenDialog: (value: boolean) => void
    afterActionCallback: IAfterActionCallback
    setAfterActionCallback: (value: IAfterActionCallback) => void
    currentScreenItem: screenMode
    pushMode: (mode: screenMode) => void
    popMode: () => void
    resetMenuStack: () => void
    resetStackAndPushMenu: (menuID: number) => void
    pushMenu: (menuID: number) => void
    popMenu: () => void
    replaceLastMenu: (menuID: number) => void
    menusStack: number[]
}

// Create the context with default values
const CurrentScreenContext = createContext<CurrentScreenContextType | undefined>(undefined)

// Create a provider component
export const CurrentScreenProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { setAlert } = useAlert()
    const { t } = useTranslation()
    const [hasActionsRequireBulk, setHasActionsRequireBulk] = useState<boolean>(false)
    const [currentPageName, setCurrentPageName] = useState<string>('')
    const [currentPageIcon, setCurrentPageIcon] = useState<string>('')
    const [selectedRecordIDs, setSelectedRecordIDs] = useState<number[]>([])
    const [currentRoute, setCurrentRoute] = useState<string>('')
    const [currentMenuID, setCurrentMenuID] = useState<number>(-1)
    const [actions, setActions] = useState<IAction[]>([])
    const [selectedAction, setSelectedAction] = useState<IAction | null>(null)
    const [componentMap, setComponentMap] = useState<any>({})
    const [DynamicComponent, setDynamicComponent] = useState<React.ComponentType<any> | null>(null)

    const [openQuickActionDialog, setOpenQuickActionDialog] = useState<boolean>(false)
    const [openFullActionDialog, setOpenFullActionDialog] = useState<boolean>(false)
    const [openSwipeScreenDialog, setOpenSwipeScreenDialog] = useState<boolean>(false)
    const [afterActionCallback, setAfterActionCallback] = useState<IAfterActionCallback>({
        save: false,
        item: null,
        error: '',
        operation: Operation.Initial,
    })
    const [stack, setStack] = useState<screenMode[]>([
        {
            mode: 'list',
            item: null,
            url: location.pathname.split('/').filter(Boolean).pop() + '',
        },
    ])
    const [menusStack, setMenusStack] = useState<number[]>([-1])
    const currentScreenItem = stack[stack.length - 1]
    const pushMode = (mode: screenMode) => {
        setStack(prev => [...prev, mode])
    }
    const popMode = () => {
        setStack(prev => (prev.length > 1 ? prev.slice(0, -1) : prev))
    }

    const resetMenuStack = () => {
        setMenusStack([-1])
    }

    const resetStackAndPushMenu = (menuID: number) => {
        setMenusStack([-1])
        setMenusStack(prev => [...prev, menuID])
    }

    const pushMenu = (menuID: number) => {
        setMenusStack(prev => [...prev, menuID])
    }
    const popMenu = () => {
        console.log('popMenu:' + menusStack.length)
        if (menusStack.length > 2) {
            setMenusStack(prev => (prev.length > 1 ? prev.slice(0, -1) : prev))
        }
    }
    const replaceLastMenu = (menuID: number) => {
        setMenusStack(prev => [...prev.slice(0, -1), menuID])
    }

    const handleSelectedAction = (shortCode: string) => {
        const tmpAction = actions.find(action => action.shortCode === shortCode)
        const index = actions.findIndex(action => action.shortCode === shortCode)
        if (tmpAction) setSelectedAction(tmpAction)

        if (index > -1) {
            const tmpDynamicComponent = componentMap[index] as React.ComponentType<any>
            if (tmpDynamicComponent) {
                setDynamicComponent(tmpDynamicComponent)
                if (tmpAction?.actionType === 'form') {
                    if (Boolean(tmpAction?.keepActionsDialogOpened) === true) {
                        setOpenFullActionDialog(false)
                        setOpenSwipeScreenDialog(true)
                        setOpenQuickActionDialog(true)
                    } else {
                        setOpenQuickActionDialog(false)
                        setOpenFullActionDialog(true)
                        setOpenSwipeScreenDialog(true)
                    }
                } else {
                    if (Boolean(tmpAction?.keepActionsDialogOpened) === true) {
                        setOpenFullActionDialog(false)
                        setOpenSwipeScreenDialog(true)
                        setOpenQuickActionDialog(true)
                    } else {
                        setOpenQuickActionDialog(false)
                        setOpenSwipeScreenDialog(false)
                        setOpenFullActionDialog(true)
                    }
                }
            }
        }
        console.log('handleSelectedAction', shortCode)
    }

    const handleDownload = async (prm: any, userName: string, OpUserID: number, OrganizationID: number) => {
        console.log('selectedAction.shortCode:', JSON.stringify(selectedAction?.shortCode))
        console.log('currentRoute:', currentRoute)
        console.log('handleProceed form values:', JSON.stringify(prm))

        let tmpURL = process.env.REACT_APP_METHODS_API! + selectedAction?.actionURL + ''
        tmpURL = tmpURL + '?' + prm + '=1'
        tmpURL = tmpURL.replace('{route}', currentRoute)

        try {
            const response = await api.get(tmpURL, { responseType: 'blob' })
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', Methods.generateTimestamp() + '_' + currentRoute + '.' + prm) // Set the file name
            document.body.appendChild(link)
            link.click()
            link.remove()
            setOpenSwipeScreenDialog(false)
            setAlert({
                message: t('Download completed successfully.'),
                severity: 'success',
                datetime: Date.now().toString(),
            })
            setSelectedAction(null)
        } catch (error: any) {
            setAlert({
                message: error.response.data.message,
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const handleDelete = async (prm: any, userName: string, OpUserID: number, OrganizationID: number) => {
        console.log('selectedAction.shortCode:', JSON.stringify(selectedAction?.shortCode))
        console.log('currentRoute:', currentRoute)
        console.log('handleProceed form values:', JSON.stringify(prm))

        let tmpURL = process.env.REACT_APP_METHODS_API! + selectedAction?.actionURL + ''
        tmpURL = tmpURL.replace('{route}', currentRoute)
        tmpURL = tmpURL.replace('{ids}', prm)
        tmpURL = tmpURL.replace('{userName}', userName + '')
        tmpURL = tmpURL.replace('{opUserID}', OpUserID + '')
        tmpURL = tmpURL.replace('{organizationID}', OrganizationID + '')

        try {
            const response = await api.delete(tmpURL)

            if (!response.data) {
                // get error message from body or default to response status
                const error = (response.data && response.data.message) || response.status

                setAlert({
                    message: error,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
                return Promise.reject(error)
            } else {
                setOpenSwipeScreenDialog(false)
                setAfterActionCallback({
                    save: true,
                    item: { prm },
                    error: '',
                    operation: Operation.Delete,
                })
                setAlert({
                    message: t('Record deleted successfully.'),
                    severity: 'success',
                    datetime: Date.now().toString(),
                })
                setSelectedAction(null)
                popMenu()
            }
        } catch (error: any) {
            setAlert({
                message: error.response.data.message,
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }

    const handleProceed = async (prm: any, userName: string, OpUserID: number, OrganizationID: number) => {
        switch (selectedAction?.shortCode) {
            case 'deleterecord':
                handleDelete(prm, userName, OpUserID, OrganizationID)
                break
            case 'downloads':
                handleDownload(prm, userName, OpUserID, OrganizationID)
                break
            default:
                console.log('handleProceed form values:', JSON.stringify(prm))
        }
    }

    useEffect(() => {
        if (location.pathname === '/') {
            return
        }
        setCurrentRoute(location.pathname.split('/').filter(Boolean).pop() + '')
        setStack(prev => [
            {
                mode: 'list',
                item: null,
                url: location.pathname.split('/').filter(Boolean).pop() + '',
                menuID: -1,
            },
        ])
    }, [location.pathname])

    const getFormActions = async () => {
        if (
            (currentRoute === 'undefined' || currentRoute === '' || currentRoute === 'login') &&
            (location.pathname.split('/').filter(Boolean).pop() === '' ||
                location.pathname === '/' ||
                location.pathname === '/login' ||
                location.pathname === '/forgotpassword' ||
                location.pathname === '/signup' ||
                location.pathname === '/dashboard' ||
                location.pathname.split('/').filter(Boolean)[0] === 'signupconfirm' ||
                location.pathname.split('/').filter(Boolean)[0] === 'resetpassword')
        ) {
            return
        }

        console.log('currentScreenItem:', currentScreenItem)
        if (
            currentScreenItem.url == '' ||
            currentScreenItem.url == 'login' ||
            currentScreenItem.url == 'undefined' ||
            typeof currentScreenItem.url == 'undefined'
        ) {
            console.log('currentRoute is undefined')
            return
        }

        const url =
            'menus/' + currentScreenItem.url + '/menuactionsbuttons?ref=1&menuActionType=' + currentScreenItem.mode

        try {
            const response = await api.get(url)

            if (!response.data) {
                setAlert({
                    message: response.status + ':' + response.statusText,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            } else {
                type JsonObject = {
                    MenuActionID: number
                    Name: string
                    Icon: string
                    KeepActionsDialogOpened: boolean
                    ComponentToRenderPath: string
                    RequiresBulkRecordsSelected: boolean
                    RequiresActionConfirmation: boolean
                    BulkDropDownURL: string
                    MenuActionShortCode: string
                    MenuActionTypeShortCode: string
                    ActionURL: string
                }

                response.data = Methods.JSONUnminify(response.data)

                setHasActionsRequireBulk(response.data.some((item: JsonObject) => item.RequiresBulkRecordsSelected))

                const transformedArray = response.data.map(
                    ({
                        Name,
                        Icon,
                        KeepActionsDialogOpened,
                        ComponentToRenderPath,
                        RequiresBulkRecordsSelected,
                        RequiresActionConfirmation,
                        BulkDropDownURL,
                        MenuActionShortCode,
                        MenuActionTypeShortCode,
                        ActionURL,
                    }: JsonObject) => ({
                        label: Name,
                        icon: Icon,
                        keepActionsDialogOpened: KeepActionsDialogOpened,
                        componentToRenderPath: ComponentToRenderPath,
                        requiresBulkRecordsSelected: RequiresBulkRecordsSelected,
                        requiresActionConfirmation: RequiresActionConfirmation,
                        bulkDropDownURL: BulkDropDownURL,
                        shortCode: MenuActionShortCode,
                        actionType: MenuActionTypeShortCode,
                        actionURL: ActionURL,
                    })
                )

                //We have to generate the components this way due (created and passing first the componentMap) to  string interpolation and module imports
                const importedFormComponents: React.ComponentType<any>[] = []

                for (const item of transformedArray as IAction[]) {
                    //const formComponentModule = lazy(() => import(`../${item.componentToRenderPath}`))
                    const formComponentModule = lazy(() => import(`../components/${item.componentToRenderPath}`))
                    importedFormComponents.push(formComponentModule)
                }

                setActions(transformedArray)
                setComponentMap(importedFormComponents)
            }
        } catch (error: any) {
            setAlert({
                message: error.message + ' ' + error.response.data,
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }
    }
    useEffect(() => {
        const isReloaded = sessionStorage.getItem('isReloaded')

        if (!isReloaded) {
            // This only runs on the first load of the page in this session
            console.log('First load')

            // Set flag to indicate page has loaded once in this session
            sessionStorage.setItem('isReloaded', 'true')
        } else {
            if (location.pathname === '/') {
                return
            }
            setCurrentRoute(location.pathname.split('/').filter(Boolean).pop() + '')
            setStack(prev => [
                {
                    mode: 'list',
                    item: null,
                    url: location.pathname.split('/').filter(Boolean).pop() + '',
                    menuID: -1,
                },
            ])
            getFormActions()
        }
    }, [i18n.language])

    useEffect(() => {
        getFormActions()
    }, [currentScreenItem.url, currentScreenItem.item, currentScreenItem.mode])

    useEffect(() => {
        console.log('currentRoute:', currentRoute)
        console.log('location.pathname:', location.pathname)
        const getCurrentPageTranslate = async () => {
            if (
                (currentRoute === 'undefined' || currentRoute === '' || currentRoute === 'login') &&
                (location.pathname.split('/').filter(Boolean).pop() === '' ||
                    location.pathname === '/' ||
                    location.pathname === '/login' ||
                    location.pathname === '/dashboard' ||
                    location.pathname === '/forgotpassword' ||
                    location.pathname === '/signup' ||
                    location.pathname.split('/').filter(Boolean)[0] === 'signupconfirm' ||
                    location.pathname.split('/').filter(Boolean)[0] === 'resetpassword')
            ) {
                return
            }
            const url = 'menus/' + currentScreenItem.url + '/menunametranslation?lang=' + i18n.language

            try {
                const response = await api.get(url)

                if (!response.data) {
                    setAlert({
                        message: response.status + ':' + response.statusText,
                        severity: 'error',
                        datetime: Date.now().toString(),
                    })
                } else {
                    setCurrentPageName(response.data[0].Name)
                }
            } catch (error: any) {
                setAlert({
                    message: error.message + ' ',
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }
        getCurrentPageTranslate()
    }, [i18n.language])

    return (
        <CurrentScreenContext.Provider
            value={{
                hasActionsRequireBulk,
                setHasActionsRequireBulk,
                currentPageName,
                setCurrentPageName,
                currentPageIcon,
                setCurrentPageIcon,
                selectedRecordIDs,
                setSelectedRecordIDs,
                currentRoute,
                setCurrentRoute,
                currentMenuID,
                setCurrentMenuID,
                actions,
                setActions,
                selectedAction,
                setSelectedAction,
                componentMap,
                DynamicComponent,
                setComponentMap,
                handleSelectedAction,
                handleProceed,
                openQuickActionDialog,
                setOpenQuickActionDialog,
                openFullActionDialog,
                setOpenFullActionDialog,
                openSwipeScreenDialog,
                setOpenSwipeScreenDialog,
                afterActionCallback,
                setAfterActionCallback,
                currentScreenItem: stack[stack.length - 1],
                pushMode,
                popMode,
                resetMenuStack,
                resetStackAndPushMenu,
                pushMenu,
                popMenu,
                replaceLastMenu,
                menusStack,
            }}
        >
            {children}
        </CurrentScreenContext.Provider>
    )
}

// Custom hook to use the context
export const useCurrentScreen = () => {
    const context = useContext(CurrentScreenContext)
    if (!context) {
        throw new Error('useCurrentScreen must be used within a CurrentScreenProvider')
    }
    return context
}
