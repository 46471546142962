import { Container, SxProps, Tab, Theme } from '@mui/material'
import api from 'Utils/APIService'
import { Methods } from 'Utils/Utils'
import { ItemType } from 'components/Common/UseForm/UFOrderListSelect/UFOrderListSelect'
import { useAlert } from 'contexts/AlertContext'
import { useAuth } from 'contexts/AuthContext'
import i18n from 'i18n'
import { useEffect, useState } from 'react'
import CommonTabIcon from '../CommonTabIcon/CommonTabIcon'
import CommonTabContainer from '../CommonTabContainer/CommonTabContainer'
import CommonInfiniteScrollList from 'components/Common/CommonInfiniteScrollList/CommonInfiniteScrollList'
import CommonTabHeader from '../CommonTabHeader/CommonTabHeader'
import { Info } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { Operation } from 'components/Common/Types/CommonType'
import { useCurrentScreen } from 'contexts/CurrentScreenContext'

interface CommonTabLoaderProps {
    DetailsTabComponent: any
    item: any
    control: any
    setValue: any
    watch?: any
    operation: Operation
    formMainAPI: string
    handleTabChangeParent: (event: React.SyntheticEvent, newValue: string) => void
    commonTabHeaderCustomSX?: SxProps<Theme> | undefined
}

interface MenuTab extends ItemType {
    Icon: string
    childRouteAPI: string
    childComponent: string
    childForm: string
    childFormURL: string
    childListRowFormatComponent: string
    menuID: number
    componentParams: string
}

const CommonTabLoader = (props: CommonTabLoaderProps) => {
    const {
        DetailsTabComponent,
        item,
        control,
        setValue,
        operation,
        formMainAPI,
        handleTabChangeParent,
        watch,
        commonTabHeaderCustomSX,
    } = props
    const { setAlert } = useAlert()
    const { user } = useAuth()
    const { currentMenuID, replaceLastMenu, menusStack } = useCurrentScreen()
    const [menuTabs, setMenuTabs] = useState<MenuTab[]>([])
    const [tabValue, setTabValue] = useState<string>('1')
    const { t } = useTranslation()
    const [components, setComponents] = useState<React.ComponentType<any>[]>([])
    const [formComponents, setFormComponents] = useState<React.ComponentType<any>[]>([])
    const [listItemTextComponents, setListItemTextComponents] = useState<React.ComponentType<any>[]>([])
    const [counters, setCounters] = useState<{ [key: string]: string }>()
    useEffect(() => {
        const fetchMenuTabs = async () => {
            if (!menusStack) return
            if (menusStack.length === 0 || menusStack === null) return
            if (isNaN(menusStack[menusStack.length - 1])) return

            try {
                const responseAll = await api.get(
                    process.env.REACT_APP_METHODS_API +
                        'menus/' +
                        menusStack[menusStack.length - 1] +
                        '/currentrolemenumenutabs?userid=' +
                        user!.OpUserID
                )

                if (!responseAll.data) {
                    setAlert({
                        message: responseAll.status + ':' + responseAll.statusText,
                        severity: 'error',
                        datetime: Date.now().toString(),
                    })
                } else {
                    responseAll.data = Methods.JSONUnminify(responseAll.data)
                    const rawData = responseAll.data

                    const importedComponents: React.ComponentType<any>[] = []
                    const importedFormComponents: React.ComponentType<any>[] = []
                    const importedListItemTextComponents: React.ComponentType<any>[] = []
                    const countersURLs: string[] = []
                    for (const itemRD of rawData as MenuTab[]) {
                        countersURLs.push(
                            formMainAPI + '/' + item[Object.keys(item)[0]] + '/' + itemRD.childRouteAPI + '?count=1'
                        )
                        /*const componentModule = await import(`../../../../${itemRD.childComponent}`)
                        importedComponents.push(componentModule.default)*/

                        if (itemRD.childListRowFormatComponent != null) {
                            const listItemTextComponentModule = await import(
                                `../../../../${itemRD.childListRowFormatComponent}`
                            )

                            importedListItemTextComponents.push(listItemTextComponentModule.default)
                        } else {
                            importedListItemTextComponents.push(() => <div></div>)
                        }

                        if (itemRD.childForm !== null) {
                            const formComponentModule = await import(`../../../../${itemRD.childForm}`)
                            importedFormComponents.push(formComponentModule.default)
                        } else {
                            //so we keep having the same amount of components
                            importedFormComponents.push(() => <div></div>)
                        }
                    }

                    //setComponents(importedComponents)
                    setListItemTextComponents(importedListItemTextComponents)
                    setFormComponents(importedFormComponents)
                    setMenuTabs(rawData)
                    const countersData = await Methods.getCountersBulk(countersURLs)
                    setCounters(prevCounters => ({
                        ...prevCounters,
                        ...countersData,
                    }))
                }
            } catch (error: any) {
                console.error('Failed to fetch subjects')
                setAlert({
                    message: error.message,
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            }
        }
        fetchMenuTabs()
    }, [])

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue)
        handleTabChangeParent(event, newValue)
        if (event.target instanceof HTMLElement) {
            const tmpTarget = event.currentTarget as HTMLElement
            if (typeof tmpTarget.dataset.tag === 'undefined') {
                //take the tag from the parent of the tmpTarget parent
                replaceLastMenu(Number(tmpTarget.parentElement!.parentElement!.dataset.tag))
            } else {
                replaceLastMenu(Number(tmpTarget.dataset.tag))
            }
        }
    }

    return (
        <>
            {menuTabs.length === 0 && (
                <Container>
                    <DetailsTabComponent
                        item={item}
                        control={control}
                        setValue={setValue}
                        watch={watch}
                        disabled={operation === Operation.Delete ? true : false}
                    />
                </Container>
            )}
            {menuTabs.length > 0 && (
                <>
                    <CommonTabHeader handleTabChange={handleTabChange} customSX={commonTabHeaderCustomSX}>
                        <Tab label={t('Details')} value={'1'} icon={<Info />} data-tag={currentMenuID} />

                        {menuTabs.length === 0 && <></>}
                        {menuTabs.map((menuTab, index) => {
                            return (
                                <Tab
                                    key={index}
                                    label={menuTab.Name}
                                    value={menuTab.ItemID + ''}
                                    icon={
                                        <CommonTabIcon
                                            strSVG={menuTab.Icon}
                                            counter={
                                                counters == null
                                                    ? 0
                                                    : counters[menuTab.childRouteAPI] == null
                                                    ? 0
                                                    : parseInt(counters[menuTab.childRouteAPI])
                                            }
                                        />
                                    }
                                    data-tag={menuTab.menuID}
                                />
                            )
                        })}
                    </CommonTabHeader>

                    {control != null && (
                        <CommonTabContainer displayTab={'1' === tabValue}>
                            <Container>
                                <DetailsTabComponent
                                    item={item}
                                    control={control}
                                    setValue={setValue}
                                    watch={watch}
                                    disabled={operation === Operation.Delete ? true : false}
                                />
                            </Container>
                        </CommonTabContainer>
                    )}
                    {menuTabs.length === 0 && <></>}
                    {menuTabs.map((menuTab, index) => {
                        return (
                            <CommonTabContainer key={index} displayTab={menuTab.ItemID + '' === tabValue + ''}>
                                <CommonInfiniteScrollList
                                    url={formMainAPI + '/' + item[Object.keys(item)[0]] + '/' + menuTab.childRouteAPI}
                                    isVisibleNow={tabValue === menuTab.ItemID}
                                    ListItemTextAttributes={listItemTextComponents[index]}
                                    CRUDEForm={formComponents[index]}
                                    hideAddition={true}
                                    CRUDEFormURL={menuTab.childFormURL}
                                    hideMultiSelect={true}
                                    componentParams={menuTab.componentParams}
                                />
                            </CommonTabContainer>
                        )
                    })}
                </>
            )}
        </>
    )
}

export default CommonTabLoader
