// SocketContext.tsx
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { io, Socket } from 'socket.io-client'
import { useAlert } from './AlertContext'
import { useAuth } from './AuthContext'

interface SocketContextType {
    socket: Socket | null
    isSocketReady: boolean
    notificationReceived: boolean
    handleNotifications: (userID: number) => void
}

const SocketContext = createContext<SocketContextType | undefined>(undefined)

interface SocketProviderProps {
    children: ReactNode
}

export const useSocket = (): SocketContextType => {
    const context = useContext(SocketContext)
    if (!context) {
        throw new Error('useSocket must be used within a SocketProvider')
    }
    return context
}

const handleCallBack = (item: any) => {
    console.log('Callback from socket register_user and connected users are:', item.connectedUsersNames)
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null)
    const [isSocketReady, setIsSocketReady] = useState(false)
    const [notificationReceived, setNotificationReceived] = useState<boolean>(false)
    const { setAlert } = useAlert()

    const headers = {
        Authorization: 'Bearer YOUR_TOKEN', // Example: Add a token
        'Custom-Header': 'YourHeaderValue', // Add any other headers as needed
    }

    useEffect(() => {
        console.log('SocketProvider mounted')
        if (isSocketReady) {
            return
        }
        console.log('Connecting socket')
        /*const newSocket = io(process.env.REACT_APP_API_URL + '', {
            extraHeaders: headers, // Add custom headers here
            transports: ['websocket'], // Ensure WebSocket transport is used
        })*/ // Replace with your server URL
        const newSocket = io(process.env.REACT_APP_API_URL + '')
        console.log('Connecting to socket:', process.env.REACT_APP_API_URL)
        newSocket.on('connect', () => {
            console.log('Connected to socket:', newSocket.id)
            setSocket(newSocket)
            console.log('Socket connected:', newSocket.id)
            setIsSocketReady(true)
        })

        newSocket.on('connect_error', err => {
            console.error('Connection error:', err.message)
        })

        newSocket.on('disconnect', () => {
            setIsSocketReady(false)
            reconnect(newSocket)
        })

        // Cleanup on unmount
        return () => {
            console.log('Disconnecting socket:', newSocket.id)
            if (newSocket) {
                newSocket.emit('disconnect_user', '-1', handleCallBack)
                newSocket.close()
            }
        }
    }, [])

    const reconnect = (newSocket: Socket) => {
        // Attempt to reconnect with exponential backoff
        const reconnectInterval = setInterval(() => {
            if (!newSocket.connected) {
                console.log('Attempting to reconnect...')
                newSocket.connect()
            } else {
                clearInterval(reconnectInterval)
            }
        }, 1000) // Adjust this interval as needed
    }

    const handleNotifications = async (userID: number) => {
        socket!.on('sendmessageResponse', (response: any) => {
            console.log('Received sendmessageResponse')
            console.log(JSON.stringify(response))
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'socketNotification',
                    message: response.message,
                })
            }

            setAlert({
                message: response.message,
                severity: 'success',
                datetime: Date.now().toString(),
            })

            // Optionally, update the badge count
            /*if (navigator.setAppBadge) {
                navigator.setAppBadge(1).catch((error: any) => console.error('Failed to set badge:', error))
            } else {
                console.log('Badging API not supported.')
            }*/
        })

        socket!.on('user-connected', (response: any) => {
            setNotificationReceived(true)
            console.log('Received user-connected')
            console.log('Coming from :', response.userId)
            console.log('We are:', socket!.id)
            console.log('User ID:', userID)
            console.log('Total users:', response.totalUsers)
            if (response.userId === userID) {
                console.log('We are the same user')
                return
            }

            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'socketNotification',
                    message: response.message,
                })
            }

            setAlert({
                message: response.message,
                severity: 'success',
                datetime: Date.now().toString(),
            })
        })

        socket!.on('user-welcome', (response: any) => {
            console.log('Received welcome')
            setNotificationReceived(true)
            console.log(JSON.stringify(response))
            if (navigator.serviceWorker && navigator.serviceWorker.controller) {
                navigator.serviceWorker.controller.postMessage({
                    type: 'socketNotification',
                    message: response.message,
                    userId: response.userId,
                })
            }

            setAlert({
                message: response.message,
                severity: 'success',
                datetime: Date.now().toString(),
            })
        })
    }

    return (
        <SocketContext.Provider value={{ socket, isSocketReady, notificationReceived, handleNotifications }}>
            {children}
        </SocketContext.Provider>
    )
}
