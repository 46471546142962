// import './_ResetPassword.scss'
import React, { useState } from 'react'
import { Box, Button, Grid, Typography, Link } from '@mui/material'

import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import UFTextField from '../../../Common/UseForm/UFTextField/UFTextField'
import { EInputType } from '../../../Common/Interfaces/IInputProps'
import { IResetPassword } from './IResetPassword'
import { useForm } from 'react-hook-form'
import { useAlert } from '../../../../contexts/AlertContext'
import Onboarding from '../../../../layouts/Onboarding/Onboarding'
import { useLoading } from '../../../../contexts/LoadingContext'
import api from '../../../../Utils/APIService'

interface ResetPasswordProps {
    token?: string
}

const ResetPassword: React.FC<ResetPasswordProps> = () => {
    const { setLoading, isLoading } = useLoading()
    const navigate = useNavigate()
    const { setAlert } = useAlert()
    const [values, setValues] = React.useState({
        password: '',
        showPassword: false,
    })

    const [confirmValues, setConfirmValues] = React.useState({
        password: '',
        showPassword: false,
    })

    const params = useParams()
    const token = params.token
    // const theme = createTheme()
    const { t } = useTranslation()

    const methods = useForm<IResetPassword>({ defaultValues: { password: '', confirmpassword: '' } })
    const { handleSubmit, control, setValue } = methods

    const handleResetPassword = async (prm: IResetPassword) => {
        if (prm.password !== prm.confirmpassword) {
            //setAlertSeverity({ status: 'error', msg: t('Passwords do not match.') })
            setAlert({
                message: 'Passwords do not match.',
                severity: 'error',
                datetime: Date.now().toString(),
            })
            return
        }

        setLoading(true)

        try {
            const prmItem = {
                Token: token,
                Password: prm.password,
            }

            const response = await api.post('users/resetpassword', JSON.stringify(prmItem))
            if (!response.data) {
                const errorData = response.data

                //setAlertSeverity({ status: 'error', msg: t(errorData.msg) })
                setAlert({
                    message: t(errorData.msg),
                    severity: 'error',
                    datetime: Date.now().toString(),
                })
            } else {
                //setAlertSeverity({ status: 'OK', msg: t('Password is updated') })
                setAlert({
                    message: t('Password is updated'),
                    severity: 'success',
                    datetime: Date.now().toString(),
                })
                setValues({ ...values, password: '' })
                setConfirmValues({ ...confirmValues, password: '' })
                localStorage.clear()
                navigate('/login')
            }
        } catch (err: any) {
            //setAlertSeverity({ status: 'error', msg: t('Error occurred. Please try again later.') })

            setAlert({
                message: err.response.data.msg,
                severity: 'error',
                datetime: Date.now().toString(),
            })
        }

        setLoading(false)
    }

    const handleError = (error: any) => {
        setAlert({
            message: t('Error'),
            severity: 'error',
            datetime: Date.now().toString(),
        })
    }
    return (
        <Onboarding>
            <Typography component="h1" variant="h5" align="center">
                {t('Reset Password')}
            </Typography>
            <Box component="form" onSubmit={handleSubmit(handleResetPassword, handleError)} sx={{ mt: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="password"
                            label={t('Password')}
                            required={t('Empty field!')}
                            autoComplete={'current-password'}
                            autoFocus={true}
                            inputType={EInputType.Password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <UFTextField
                            setValue={setValue}
                            control={control}
                            fullWidth={true}
                            name="confirmpassword"
                            label={t('Confirm Password')}
                            required={t('Empty field!')}
                            autoComplete={'current-password'}
                            autoFocus={true}
                            inputType={EInputType.Password}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button type="submit" disabled={isLoading} variant="contained" color="primary" fullWidth>
                            {isLoading ? t('Sending password recovery email') : t('Reset Password')}
                        </Button>
                    </Grid>

                    <Grid item xs>
                        <Typography variant="body2" color="textPrimary" align="left">
                            <Link underline="hover" href="/login">
                                {t('Home')}
                            </Link>
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
        </Onboarding>
    )
}

export default ResetPassword
